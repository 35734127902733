/*------------------------------------*\
    Blog
\*------------------------------------*/

.blog-front {
  .blog-item {
    @include breakpoint(md) {
      margin: 0;
    }
    @include breakpoint(lg) {
      margin: 0;
    }
  }
}

.blog-item {
  margin-bottom: 30px;
  &:last-child {
    margin: 0;
  }
}

.blog-item__image {
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
}

.blog-item__content {
  padding: 15px;
  background: $color-gray;
  h3 {
    color: $color-black;
    text-transform: uppercase;
    margin: 0 0 5px 0;
    &:hover {
      text-decoration: none;
      color: $color-primary;
    }
  }
  a:hover {
    text-decoration: none;
  }
  time {
    font-weight: $bold;
    margin-bottom: 15px;
    color: $color-gray02;
    display: block;
  }
  &--readmore {
    text-align: right;
  }
  &--readmore a {
    font-weight: $bold;
    text-transform: uppercase;
    &:hover {
      color: $color-secondary;
    }
  }
}

.pagination__blog {
  text-align: center;
  margin-bottom: 0;
}


/*------------------------------------*\
    Blog Single
\*------------------------------------*/

.blog-single {
  font-size: 16px;
  time {
    position: absolute;
    left: 0;
    bottom: 0;
    background: $color-primary;
    padding: 10px 15px;
    color: $color-white;
    font-size: 14px;
    @include breakpoint(xs-max) {
      font-size: 13px;
      padding: 8px;
    }
    .fa {
      margin-right: 5px;
      font-size: 20px;
      @include breakpoint(xs-max) {
        font-size: 18px;
      }
    }
  }
  .blog-item__content {
    padding: 30px 15px 20px 15px;
    img {
      max-width: 100%;
    }
    iframe {
      max-width: 100%;
    }
  }
}

.blog-single__content {
  img {
    padding-top: 30px;
    padding-bottom: 30px;
    height: auto;
    width: 100%;
    max-width: 100%;
  }
  #one-fourth {
    width: 25%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    @include breakpoint(xs-max) {
      width: 100%;
    }
    @include breakpoint(xs) {
      width: 100%;
    }
    @include breakpoint(sm) {
      width: 50%;
    }
  }
  #one-third {
    width: 33.3333333333%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    @include breakpoint(xs-max) {
      width: 100%;
    }
    @include breakpoint(xs) {
      width: 100%;
    }
  }
  #half {
    width: 50%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    @include breakpoint(xs-max) {
      width: 100%;
    }
    @include breakpoint(xs) {
      width: 100%;
    }
  }
  #full {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    float: left;
  }
}

blockquote {
  font-size: 18px;
  clear: both;
}
