/**
 * Variables and Bootstrap overrides
 */


/* Colors */

$color-default-inverse: rgba(255, 255, 255, 0.7);
$color-default: rgba(0, 0, 0, 0.7);
$color-primary: #FF6700;
$color-primary01: #e54729;
$color-secondary: #212121;
$color-secondary01: #424242;
$color-gray: #eeeeee;
$color-gray01: #cccccc;
$color-gray02: #999999;
$color-white: #ffffff;
$color-black: #000000;
$color-green: #00843B;

/* Font Weight */

$light: 100;
$normal: 400;
$medium: 600;
$bold: 800;
$extra-bold: 900;

/* Fonts */

$font-primary: 'Open Sans',
sans-serif;
$font-secondary: 'Oswald',
sans-serif;

/* Breakpoints */

@mixin breakpoint($point) {
  @if $point==xs-max {
    @media (max-width: 480px) {
      @content;
    }
  }
  @else if $point==xs {
    @media (min-width: 481px) and (max-width: 767px) {
      @content;
    }
  }
  @else if $point==sm {
    @media (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  }
  @else if $point==md {
    @media (min-width: 992px) and (max-width: 1280px) {
      @content;
    }
  }
  @else if $point==lg {
    @media (min-width: 1281px) {
      @content;
    }
  }
}
