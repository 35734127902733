/*------------------------------------*\
    Shop
\*------------------------------------*/

.sidebar-item {
  margin-bottom: 30px;
  .main-title {
    margin-bottom: 15px;
  }
  .panel-group {
    .panel {
      border-radius: 0px;
      box-shadow: none;
      margin-top: 0px;
      border-top-width: 0px;
      &:first-of-type {
        border-top-width: 1px;
      }
      &.active {
        .panel-title a {
          background-color: $color-primary;
          color: $color-white;
        }
        .panel-title .fa {
          background-color: $color-primary01;
          color: $color-white;
        }
      }
    }
  }
  .panel-heading {
    padding: 0px;
  }
  .panel.has-child {
    .panel-title {
      padding-right: 35px;
    }
  }
  .panel-title {
    font-size: 14px;
    position: relative;
    font-family: $font-primary;
    .amount {
      font-weight: $normal;
    }
    a {
      padding: 10px;
      height: 35px;
      display: block;
      background-color: whitesmoke;
      color: #000;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover {
        text-decoration: underline !important;
      }
      &:focus {
        text-decoration: none;
      }
    }
    .fa {
      height: 35px;
      width: 35px;
      line-height: 35px;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      color: #333;
      background: #eaeaea;
      &.collapsed {
        &:before {
          vertical-align: middle;
          display: inline-block;
          @include rotate(180deg);
        }
      }
      &:before {
        @include transition(.2s ease-in-out);
      }
    }
  }
  .list-group {
    margin: 0px;
    li {
      border-radius: 0px;
      border-left-width: 0px;
      border-right-width: 0px;
      &:last-child {
        border-bottom-width: 0px;
      }
      &.active {
        border-color: #ddd;
        background-color: #FFF;
      }
      &.active a {
        color: $color-primary;
        font-weight: 700;
      }
      a {
        color: #000;
        &:hover {
          text-decoration: none;
          color: $color-primary;
        }
      }
    }
  }
  .list-manufacturers {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
    li {
      a {
        display: block;
        padding: 10px;
        color: $color-secondary;
        font-weight: $bold;
        text-transform: uppercase;
        font-size: 12px;
        border-bottom: 1px solid $color-gray;
        &:hover {
          color: $color-primary;
          text-decoration: none;
        }
        &:focus {
          text-decoration: none;
        }
      }
      &:last-child {
        a {
          border: none;
        }
      }
    }
    &.active {
      margin-bottom: 10px;
      li {
        margin-bottom: 10px;
        span.label {
          display: block;
          color: #000;
          text-align: left;
          font-size: 13px;
          padding: 10px;
          margin-bottom: 10px;
          text-transform: uppercase;
          border-bottom: 1px solid $color-gray;
        }
        span.value {
          display: inline-block;
          padding: 0 10px;
          font-size: 12px;
          font-weight: $bold;
          text-transform: uppercase;
        }
        .btn-remove {
          display: inline-block;
          padding: 0;
          border: none;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

.range-slider-text {
  text-transform: uppercase;
  font-weight: $bold;
  margin-bottom: 10px;
}

.range-slider {
  height: 4px;
  margin: 18px 0;
}

.noUi-background {
  background: #CCC;
}

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
  background: #d12010;
  cursor: pointer;
  width: 14px;
  height: 20px;
  top: -8px;
  &:before {
    content: "";
    position: absolute;
    height: 10px;
    width: 2px;
    top: 5px;
    left: 6px;
    background: #000;
    opacity: 0.3;
  }
  &.noUi-active {
    &:before {
      opacity: 0.6;
    }
  }
}

.noUi-handle-lower {
  left: 0;
}

.noUi-handle-upper {
  left: -14px;
}

.noUi-stacking .noUi-handle {
  z-index: 10;
}

.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-base {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.noUi-connect {
  background: #999;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

.noUi-dragable {
  cursor: w-resize;
}


/* Disabled state;
 */

[disabled].noUi-connect,
[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled].noUi-origin,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

.range-slider-value {
  overflow: hidden;
  margin-top: 7px;
  .value {
    font-size: 13px;
    font-weight: bold;
  }
  .value1 {
    float: left;
  }
  .value2 {
    float: right;
  }
}

.sort-products {
  text-align: right;
  margin-top: 15px;
  margin-bottom: 15px;
  .select-text {
    text-transform: uppercase;
    font-weight: $bold;
    margin-right: 5px;
    margin-left: 15px;
    @include breakpoint(xs-max) {
      display: inline-block;
      font-size: 12px;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-top: 10px;
      }
    }
    @include breakpoint(xs) {
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
  .select-sort {
    display: inline-block;
    padding: 5px 10px;
    border: 1px solid #dddddd;
    background: whitesmoke;
    box-shadow: none;
    font-weight: $bold;
    outline: none;
  }
}

.pagination {
  display: block;
  ul {
    padding: 0px;
    margin: 0px;
    display: inline-block;
    li {
      list-style-type: none;
      text-align: center;
      display: inline-block;
      font-weight: $bold;
      a {
        border: 1px solid #dddddd;
        display: table-cell;
        width: 40px;
        height: 40px;
        vertical-align: middle;
        &:hover {
          text-decoration: none;
          background-color: $color-primary;
          color: $color-white;
        }
      }
      &.active {
        background-color: $color-primary;
        color: $color-white;
        a {
          color: $color-white;
        }
      }
      .fa {
        vertical-align: middle;
      }
    }
  }
}
