/*------------------------------------*\
    Promos
\*------------------------------------*/

.promo {
  position: relative;
  /*---------  Mobile  ---------*/
  @include breakpoint(sm) {
    height: 168px;
  }
  @include breakpoint(md) {
    height: 221px;
  }
  @include breakpoint(lg) {
    height: 270px;
  }
  a {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 15px;
    &:hover,
    &:focus {
      text-decoration: none;
      .promo-text {
        border-color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  img {
    width: 100%;
    height: auto;
    color: $color-black;
    text-align: center;
  }
}

.col-md-6:first-of-type .promo {
  /*---------  Mobile  ---------*/
  @include breakpoint(xs-max) {
    margin-bottom: 15px;
  }
  @include breakpoint(xs) {
    margin-bottom: 15px;
  }
}

.promo-wrap {
  display: table;
  width: 100%;
  height: 100%;
  .promo-text {
    color: #fff;
    display: table-cell;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 3px;
    padding: 15px;
    vertical-align: top;
    height: 100%;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    vertical-align: middle;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
    @include transition(.2s ease-in-out);
    /*---------  Mobile  ---------*/
    @include breakpoint(xs-max) {
      font-size: 16px;
    }
    @include breakpoint(xs) {
      font-size: 24px;
    }
    @include breakpoint(sm) {
      font-size: 20px;
    }
  }
}

.promo-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: $color-black;
  opacity: 0.3;
}
