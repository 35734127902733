/*------------------------------------*\
    Buttons
\*------------------------------------*/

.btn {
  padding: 5px 12px;
  text-transform: uppercase;
  font-weight: $bold;
  border-radius: 0px;
  font-size: 14px;
  border-width: 0px;
  background-color: $color-primary;
  color: $color-white;
  text-align: center;
  @include transition(.2s ease-in-out);
  &:hover,
  &:focus {
    background-color: $color-primary01;
    color: $color-white;
  }
  &.big {
    padding: 15px;
  }
  &.full {
    width: 100%;
  }
  &.green {
    background-color: $color-secondary01;
    &:hover,
    &:focus {
      background-color: $color-black;
    }
  }
  &.btn-border {
    background: none;
    border: 2px solid $color-white;
    color: $color-white;
    &.red {
      border-color: $color-primary;
      color: $color-primary;
      &:hover,
      &:focus {
        color: $color-white;
        background: $color-primary;
      }
    }
    &.black {
      border-color: #000000;
      color: #000000;
      &:hover,
      &:focus {
        color: $color-white;
        background: #000000;
      }
    }
  }
}

.btn-cat-desc {
  padding: 4px 0 4px 10px;
  background: $color-white;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
}
