/*------------------------------------*\
    Utilities
\*------------------------------------*/

.display-ib {
  display: inline-block;
}

.v-middle {
  vertical-align: middle;
}

.color-green {
  color: $color-green;
}

.color-primary {
  color: $color-primary;
}

.text-center {
  text-align: center;
  .footer-title {
    text-align: center;
  }
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-nowrap {
  white-space: nowrap;
}

.bold {
  font-weight: $bold;
}

.no-margin {
  margin: 0;
}

.no-margin-bot {
  margin-bottom: 0px;
}

.no-margin-top {
  margin-top: 0px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mt35 {
  margin-top: 35px;
}

.mt45 {
  margin-top: 45px;
}

.mt55 {
  margin-top: 55px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mb45 {
  margin-bottom: 45px;
}

.mb55 {
  margin-bottom: 55px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml25 {
  margin-left: 25px;
}

.ml35 {
  margin-left: 35px;
}

.ml45 {
  margin-left: 45px;
}

.ml55 {
  margin-left: 55px;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr25 {
  margin-right: 25px;
}

.mr35 {
  margin-right: 35px;
}

.mr45 {
  margin-right: 45px;
}

.mr55 {
  margin-right: 55px;
}

.no-padding {
  padding: 0;
}

.pt30 {
  padding-top: 30px;
}

.pb30 {
  padding-bottom: 30px;
}
