/*------------------------------------*\
    Form
\*------------------------------------*/

.form-group,
.buttons-set {
  .required {
    color: #f00;
  }
}

input:not([type=checkbox]):not([type=radio]) {
  -webkit-appearance: none;
}

.form-control {
  border-radius: 0px;
  background-color: #f5f5f5;
  box-shadow: none;
  &:focus {
    box-shadow: none;
    border: 1px solid $color-gray02;
  }
}

.inverse {
  .form-control {
    background-color: $color-white;
  }
}
