/*------------------------------------*\
    Above top navigation
\*------------------------------------*/

.above-nav {
  background: $color-secondary;
  padding: 10px;
  color: $color-gray01;
  font-size: 12px;
}

.text-above {
  float: left;
  .fa {
    color: $color-primary;
  }
  /*---------  Mobile  ---------*/
  @include breakpoint(xs-max) {
    float: none;
    text-align: center;
    div:last-of-type {
      margin-top: 5px;
    }
  }
  @include breakpoint(xs) {
    float: none;
    text-align: center;
  }
}

.login-above {
  float: right;
  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    li {
      display: inline-block;
      position: relative;
      a {
        padding-left: 5px;
        padding-right: 5px;
        span {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }
  /*---------  Mobile  ---------*/
  @include breakpoint(xs-max) {
    float: none;
    text-align: center;
    margin-top: 10px;
  }
  @include breakpoint(xs) {
    float: none;
    text-align: center;
    margin-top: 10px;
  }
  a {
    color: $color-gray01;
    &:hover {
      text-decoration: underline;
    }
  }
}


/*------------------------------------*\
    Header
\*------------------------------------*/

.navbar {
  &.main-navbar {
    border: none;
    border-radius: 0px;
    margin-bottom: 0;
    background: $color-white;
  }
  .navbar-brand {
    height: auto;
    float: none;
    display: inline-block;
    vertical-align: middle;
    padding: 30px 0 30px 15px;
    img {
      max-width: 100%;
    }
    @include breakpoint(xs-max) {
      padding-left: 0;
    }
    @include breakpoint(xs) {
      padding-left: 0;
    }
    @include breakpoint(sm) {
      padding-left: 0;
    }
  }
  .navbar-header {
    float: none;
    @include breakpoint(xs-max) {
      margin: 0;
    }
    @include breakpoint(xs) {
      margin: 0;
    }
    h1 {
      margin: 0;
      @include breakpoint(xs-max) {
        text-align: center;
      }
      @include breakpoint(xs) {
        text-align: center;
      }
      @include breakpoint(sm) {
        text-align: center;
      }
    }
    .navbar-icons {
      float: right;
      text-align: right;
    }
    .navbar-time {
      width: auto;
      height: 50px;
      .opening-time {
        overflow: hidden;
        height: 50px;
        font-size: 14px;
        padding: 5px 8px;
        @include breakpoint(xs-max) {
          font-size: 12px;
          padding: 8px;
        }
      }
      .opening-time__item {
        color: $color-secondary01;
        display: block;
      }
      .nav-icon {
        float: left;
        &:hover {
          background: none;
        }
      }
    }
    .nav-icon {
      height: 50px;
      width: 50px;
      background: none;
      color: $color-secondary;
      display: inline-block;
      vertical-align: middle;
      border: 1px solid $color-gray;
      border-top: none;
      font-size: 20px;
      text-align: center;
      line-height: 50px;
      @include transition(.2s ease-in-out);
      &:hover {
        text-decoration: none;
      }
      &.fa-facebook:hover,
      &.fa-facebook:focus {
        background-color: #3b5998;
        border-color: #3b5998;
        color: $color-white;
      }
      &.fa-youtube:hover,
      &.fa-youtube:focus {
        background-color: #bb0000;
        border-color: #bb0000;
        color: $color-white;
      }
      .fa {
        font-size: 20px;
      }
      &:last-of-type {
        margin-left: -4px;
        border-left: none;
      }
    }
  }
  .navbar-collapse {
    text-align: left;
    padding-left: 0px;
    padding-right: 0px;
    float: left;
    @include breakpoint(xs-max) {
      float: none;
      margin-top: 15px;
    }
    @include breakpoint(xs) {
      float: none;
      margin-top: 15px;
    }
    @include breakpoint(sm) {
      float: none;
      margin-top: 15px;
    }
    @include breakpoint(md) {
      float: none;
      margin-top: 15px;
    }
    &.collapse {
      @include breakpoint(xs-max) {
        display: none !important;
        float: none;
      }
      @include breakpoint(xs) {
        display: none !important;
        float: none;
      }
      @include breakpoint(sm) {
        display: none !important;
        float: none;
      }
      @include breakpoint(md) {
        display: none !important;
        float: none;
      }
      &.in {
        display: block !important;
      }
    }
  }
  .navbar-search {
    @include breakpoint(xs-max) {
      width: 100%;
      float: left;
    }
    .navbar-form {
      margin: 0;
      padding: 0px;
      border: none;
      .form-group {
        margin: 0px;
        display: block;
        overflow: hidden;
        .form-control {
          height: 50px;
          width: 100%;
          background: none;
          border-color: $color-gray;
          color: $color-secondary01;
          box-shadow: none;
          border-right: none;
          &:focus {
            border-color: $color-gray01;
          }
        }
      }
      .nav-icon {
        padding: 0px;
        margin-left: 0;
        float: right;
        border-left: none;
        background: $color-primary;
        border-color: $color-primary;
        color: $color-white;
        &:hover {
          background: $color-primary01;
          border-color: $color-primary01;
        }
      }
    }
  }
}


/*------------------------------------*\
    Navigation main menu
\*------------------------------------*/

.nav-wrap {
  background: $color-secondary;
}

.megamenu-wrap {
  position: relative;
}

.navbar-nav {
  float: left;
  list-style: none;
  font-size: 14px;
  @include breakpoint(xs-max) {
    float: none;
    margin-left: 15px;
    margin-right: 15px;
  }
  @include breakpoint(xs) {
    float: none;
    margin-left: 15px;
    margin-right: 15px;
  }
  @include breakpoint(sm) {
    float: none;
    margin-left: 15px;
    margin-right: 15px;
  }
  @include breakpoint(md) {
    float: none;
    margin-left: 15px;
    margin-right: 15px;
  }
  >li {
    float: left;
    position: inherit;
    @include breakpoint(xs-max) {
      float: none
    }
    @include breakpoint(xs) {
      float: none
    }
    @include breakpoint(sm) {
      float: none
    }
    @include breakpoint(md) {
      float: none
    }
    &:last-of-type {
      @include breakpoint(xs-max) {
        margin-bottom: 15px;
      }
      @include breakpoint(xs) {
        margin-bottom: 15px;
      }
      @include breakpoint(sm) {
        margin-bottom: 15px;
      }
      @include breakpoint(md) {
        margin-bottom: 15px;
      }
    }
    &:first-of-type>a {
      margin-top: 0;
    }
    >a {
      padding: 15px 15px;
      text-transform: uppercase;
      color: $color-white;
      font-weight: $bold;
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      @include transition(all .3s ease-in-out);
      /*---------  Mobile  ---------*/
      @include breakpoint(xs-max) {
        padding: 10px;
        background: $color-primary01;
        color: $color-white;
        clear: both;
        margin-top: 10px;
        border-right: none;
      }
      @include breakpoint(xs) {
        padding: 10px;
        background: $color-primary01;
        color: $color-white;
        clear: both;
        margin-top: 10px;
        border-right: none;
      }
      @include breakpoint(sm) {
        padding: 10px;
        background: $color-primary01;
        color: $color-white;
        clear: both;
        margin-top: 10px;
        border-right: none;
      }
      @include breakpoint(md) {
        padding: 10px;
        background: $color-primary01;
        color: $color-white;
        clear: both;
        margin-top: 10px;
        border-right: none;
      }
    }
  }
}

.navbar-nav > li > a:hover,
.navbar-nav > li > a:focus,
.navbar-nav > li.active,
.navbar-nav > li.open > a:focus,
.navbar-nav > li.open > a {
  color: $color-white;
  background: $color-primary;
  /*---------  Mobile  ---------*/
  @include breakpoint(md) {
    box-shadow: none;
    &:hover {
      color: #000;
    }
  }
}

.navbar-nav > li.active {
  /*---------  Mobile  ---------*/
  @include breakpoint(md) {
    background: none;
  }
}


/* Level 1 */

ul.nav > li > .dropdown-menu {
  margin-bottom: -20px;
  background: none;
  border: 0px;
  border-radius: 0px;
  opacity: 0;
  visibility: hidden;
  padding: 0px;
  box-shadow: none;
  display: none;
  width: 100%;
  background-color: $color-primary;
  @include transition(all .3s ease-in-out);
  /*---------  Mobile  ---------*/
  @include breakpoint(xs-max) {
    opacity: 1;
    visibility: visible;
    display: block;
    position: relative;
    margin-bottom: 0;
    float: none;
  }
  @include breakpoint(xs) {
    opacity: 1;
    visibility: visible;
    display: block;
    position: relative;
    margin-bottom: 0;
    float: none;
  }
  @include breakpoint(sm) {
    opacity: 1;
    visibility: visible;
    display: block;
    position: relative;
    margin-bottom: 0;
    float: none;
  }
  @include breakpoint(md) {
    opacity: 1;
    visibility: visible;
    display: block;
    position: relative;
    margin-bottom: 0;
    float: none;
  }
}

ul.nav > li:hover > .dropdown-menu {
  opacity: 1;
  visibility: visible;
  display: block;
}

ul.nav > li > .dropdown-menu > li {
  margin: 15px 0;
  /*---------  Mobile  ---------*/
  @include breakpoint(xs-max) {
    margin: 0;
    padding: 0;
    &:last-child a {
      border-bottom: none;
    }
  }
  @include breakpoint(xs) {
    margin: 0;
    padding: 0;
    &:last-child a {
      border-bottom: none;
    }
  }
  @include breakpoint(sm) {
    margin: 0;
    padding: 0;
    &:last-child a {
      border-bottom: none;
    }
  }
  @include breakpoint(md) {
    margin: 0;
    padding: 0;
    width: inherit;
    float: none;
    &:last-child a {
      border-bottom: none;
    }
  }
  &:hover,
  :focus {
    background: none;
  }
  > a {
    color: $color-white;
    font-weight: bold;
    text-transform: uppercase;
    padding: 3px 10px;
    margin-bottom: 8px;
    border-bottom: 1px solid $color-primary01;
    white-space: normal;
    /*---------  Mobile  ---------*/
    @include breakpoint(xs-max) {
      margin: 0;
      padding: 10px 15px;
    }
    @include breakpoint(xs) {
      margin: 0;
      padding: 10px 15px;
    }
    @include breakpoint(sm) {
      margin: 0;
      padding: 10px 15px;
    }
    @include breakpoint(md) {
      margin: 0;
      padding: 10px 15px;
    }
    &:hover {
      background: none;
      color: $color-black;
    }
    &:focus {
      background: none;
    }
  }
  &.active {
    > a {
      background: none;
    }
  }
}


/* Level 2 */

ul.nav > li > .dropdown-menu > li > ul {
  list-style: none;
  padding: 0;
  /*---------  Mobile  ---------*/
  @include breakpoint(xs-max) {
    display: none
  }
  @include breakpoint(xs) {
    display: none
  }
  @include breakpoint(sm) {
    display: none
  }
  @include breakpoint(md) {
    display: none
  }
}

ul.nav > li > .dropdown-menu > li > ul > li {
  > a {
    color: $color-white;
    padding: 2px 10px;
    font-size: 13px;
    display: block;
    font-weight: $medium;
    text-transform: uppercase;
    &:before {
      content: "- ";
    }
    &:hover {
      background: none;
      color: $color-black;
      text-decoration: none;
    }
    &:focus {
      text-decoration: none;
    }
  }
}

.navbar-toggle {
  background: $color-primary;
  float: left;
  border-radius: 0;
  padding: 14px 12px;
  margin: 0;
  /*---------  Mobile  ---------*/
  @include breakpoint(xs-max) {
    display: block;
  }
  @include breakpoint(xs) {
    display: block;
  }
  @include breakpoint(sm) {
    display: block;
  }
  @include breakpoint(md) {
    display: block;
  }
  .icon-bar {
    background: $color-white;
    height: 4px;
    width: 25px;
  }
}
