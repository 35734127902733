/*------------------------------------*\
    Single product
\*------------------------------------*/

.main-image {
  margin-bottom: 15px;
  img {
    width: 100%;
  }
  &:hover {
    img {
      opacity: 0.7;
    }
  }
}

.owl-carousel {
  &.product {
    .owl-nav {
      margin: 10px 0 0 0;
      .owl-prev,
      .owl-next {
        margin: 0px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .owl-prev {
        margin-right: 10px;
      }
      .owl-next {
        margin-left: 10px;
      }
    }
    .single-image {
      margin: 1px;
      padding: 1px;
      border: 1px solid $color-gray;
      background: $color-white;
      &:hover {
        border-color: $color-gray02;
      }
    }
  }
}

.product-text {
  h2 {
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .short-description {
    padding: 15px 0;
    margin: 15px 0;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    p {
      margin: 0;
    }
    a.read-more {
      font-weight: bold;
    }
  }
  .product-meta {
    display: inline-block;
    text-transform: uppercase;
    font-weight: $bold;
    margin-right: 15px;
  }
  /*---------  Mobile  ---------*/
  @include breakpoint(xs-max) {
    margin-top: 30px;
  }
  @include breakpoint(xs) {
    margin-top: 30px;
  }
  @include breakpoint(sm) {
    margin-top: 30px;
  }
}

.product-quantity {
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  margin-right: 15px;
  .minus,
  .plus {
    display: inline-block;
    vertical-align: bottom;
    height: 34px;
    width: 34px;
    border-width: 0px;
    background: $color-primary;
    color: $color-white;
    font-size: 20px;
    font-weight: bold;
    outline: none;
    &:hover {
      background-color: $color-primary01;
    }
  }
  .plus,
  .form-control {
    margin-left: -4px;
  }
  .form-control {
    display: inline-block;
    width: 50px;
    border-left-width: 0px;
    border-right-width: 0px;
    text-align: center;
    font-weight: bold;
    /*---------  Mobile  ---------*/
    @include breakpoint(xs-max) {
      width: 40px;
    }
    @include breakpoint(xs) {
      width: 40px;
    }
    @include breakpoint(sm) {
      width: 40px;
    }
    &:focus {
      border-left-width: 0px;
      border-right-width: 0px;
      color: #333;
    }
  }
}

.catalog-product-view {
  .product-quantity {
    /*---------  Mobile  ---------*/
    @include breakpoint(xs-max) {
      margin-bottom: 30px;
    }
    @include breakpoint(xs) {
      margin-bottom: 30px;
    }
    @include breakpoint(sm) {
      margin-bottom: 30px;
    }
  }
  input.btn.red {
    /*---------  Mobile  ---------*/
    @include breakpoint(xs-max) {
      margin-bottom: 30px;
    }
    @include breakpoint(xs) {
      margin-bottom: 30px;
    }
    @include breakpoint(sm) {
      margin-bottom: 30px;
    }
  }
  input.btn.black {
    /*---------  Mobile  ---------*/
    @include breakpoint(xs-max) {
      vertical-align: top;
    }
    @include breakpoint(xs) {
      vertical-align: top;
    }
    @include breakpoint(sm) {
      vertical-align: top;
    }
  }
}

.single-product-tabs {
  margin-top: 30px;
  .nav-tabs {
    text-align: left;
    border-bottom-width: 0px;
    @include breakpoint(lg) {
      display: table;
      width: 100%;
    }
    @include breakpoint(md) {
      display: table;
      width: 100%;
    }
    @include breakpoint(sm) {
      display: table;
      width: 100%;
    }
    li {
      float: none;
      display: inline-block;
      text-transform: uppercase;
      font-weight: $bold;
      font-size: 14px;
      position: relative;
      text-align: center;
      /*---------  Mobile  ---------*/
      @include breakpoint(lg) {
        display: table-cell;
        max-width: 50px;
      }
      @include breakpoint(md) {
        display: table-cell;
        max-width: 50px;
      }
      @include breakpoint(sm) {
        display: table-cell;
        max-width: 50px;
      }
      @include breakpoint(xs-max) {
        width: 100%;
        text-align: center;
      }
      @include breakpoint(xs) {
        width: 100%;
        text-align: center;
      }
      &:last-of-type {
        border-right-width: 0px;
        a {
          margin-right: 0;
        }
      }
      a {
        padding: 15px 30px;
        border-width: 0px;
        color: $color-white;
        border-radius: 0;
        background: $color-gray02;
        @include breakpoint(xs-max) {
          padding: 10px 10px;
          font-size: 14px;
          border-bottom-width: 2px;
        }
        @include breakpoint(xs) {
          border-bottom-width: 2px;
        }
        &:hover {
          background: $color-primary;
        }
      }
      &.active {
        a {
          color: $color-white;
          border-width: 0px;
          margin-bottom: 0px;
          display: block;
          position: relative;
          background: $color-primary;
        }
      }
    }
  }
  .tab-content {
    padding: 30px 15px;
    background: $color-gray;
    .table {
      thead tr th,
      tbody tr td {
        width: 50%;
      }
      td:first-child {
        font-weight: bold;
      }
    }
    iframe {
      max-width: 100%;
    }
  }
}
