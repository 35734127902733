/*------------------------------------*\
    Breadcrumbs
\*------------------------------------*/

.breadcrumbs {
  margin-top: 30px;
  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    li {
      display: inline-block;
      margin-right: 6px;
      a {
        font-weight: $bold;
        text-decoration: none;
        color: $color-black;
        border: 2px solid #CCC;
        padding: 2px 15px;
        display: block;
        @include breakpoint(xs-max) {
          font-size: 12px;
          padding: 2px 5px;
          border-width: 2px;
          margin-bottom: 5px;
        }
        @include breakpoint(xs) {
          font-size: 12px;
          padding: 2px 5px;
          border-width: 2px;
          margin-bottom: 5px;
        }
        @include breakpoint(sm) {
          font-size: 12px;
          padding: 2px 10px;
        }
      }
      &:hover a {
        border-color: $color-secondary;
      }
      &.active {
        font-weight: $bold;
        text-decoration: none;
        color: $color-black;
        border: 2px solid $color-secondary;
        padding: 2px 15px;
        @include breakpoint(xs-max) {
          font-size: 12px;
          padding: 2px 5px;
          border-width: 2px;
          margin-bottom: 5px;
        }
        @include breakpoint(xs) {
          font-size: 12px;
          padding: 2px 5px;
          border-width: 2px;
          margin-bottom: 5px;
        }
        @include breakpoint(sm) {
          font-size: 12px;
          padding: 2px 10px;
        }
      }
    }
  }
}
