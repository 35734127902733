/*------------------------------------*\
    Cart Table
\*------------------------------------*/

.table-product {
  width: 50%;
  .product-img {
    width: 50px;
    height: 50px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    img {
      max-width: 100%;
    }
    .remove {
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0px;
      left: 0px;
      padding: 0px;
      opacity: 0;
      visibility: hidden;
      @include transition(.2s ease-in-out);
      .icon {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -25px;
        margin-left: -25px;
        background: $color-primary;
        color: $color-white;
        text-align: center;
        line-height: 48px;
        vertical-align: middle;
        &:hover {
          background-color: $color-primary01;
        }
      }
    }
  }
  &:hover .remove {
    opacity: 1;
    visibility: visible;
  }
  .product-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    text-transform: uppercase;
    a {
      color: #000;
    }
    .product-categorie {
      font-weight: $bold;
    }
    /*---------  Mobile  ---------*/
    @include breakpoint(xs-max) {
      margin-top: 0;
    }
    @include breakpoint(xs) {
      margin-top: 0;
    }
    @include breakpoint(sm) {
      margin-top: 0;
    }
  }
}

.table-sku {
  .product-sku {
    font-weight: bold;
  }
}

.table-price {
  vertical-align: middle;
  width: 15%;
  .product-price {
    font-size: 14px;
    margin-top: 0px;
  }
}

.table-quantity {
  .product-quantity {
    margin: 0px;
  }
}

.cart-price {
  margin-bottom: 15px;
  .price {
    float: right;
    color: $color-primary;
  }
  &.total {
    font-weight: $bold;
    vertical-align: text-bottom;
    .price {
      font-size: 24px;
      margin-top: -10px;
    }
  }
}

.tfoot {
  .btn {
    /*---------  Mobile  ---------*/
    @include breakpoint(xs-max) {
      margin-bottom: 10px;
      &:nth-of-type(2) {
        float: none !important;
        margin-left: 0px;
      }
      &:last-of-type {
        float: none !important;
        margin-bottom: 0px;
      }
    }
    @include breakpoint(xs) {
      margin-bottom: 10px;
      &:nth-of-type(2) {
        float: none !important;
        margin-left: 0px;
      }
      &:last-of-type {
        float: none !important;
        margin-bottom: 0px;
      }
    }
    @include breakpoint(sm) {
      margin-bottom: 10px;
      &:nth-of-type(2) {
        float: none !important;
        margin-left: 0px;
      }
      &:last-of-type {
        float: none !important;
        margin-bottom: 0px;
      }
    }
  }
}


/*------------------------------------*\
    Navigation mini cart
\*------------------------------------*/

.cart-nav {
  float: right;
  .cart-items {
    position: absolute;
    bottom: -5px;
    right: -5px;
    height: 26px;
    width: 26px;
    color: $color-white;
    z-index: 9999;
    background: #000;
    border-radius: 100%;
    text-align: center;
    padding-top: 5px;
    font-weight: $bold;
    font-size: 12px;
    @include transition(all .2s ease-in-out);
  }
  &:hover {
    .cart-items {
      transform: scale(1.1);
    }
  }
  .dropdown-menu {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: 0 none;
    border-radius: 0;
    box-shadow: none;
    padding: 0px;
    margin: 0px;
    float: none;
    right: 0px;
    left: auto;
    width: 300px;
    /*---------  Mobile  ---------*/
    @include breakpoint(xs-max) {
      width: auto;
    }
  }
  li {
    background: $color-primary;
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    @include clearfix();
    .cart-small-img {
      width: 30%;
      margin-right: 5%;
      float: left;
      position: relative;
      img {
        width: 100%;
      }
      .remove {
        position: absolute;
        top: 0px;
        bottom: 0px;
        right: 0px;
        left: 0px;
        background: $color-primary;
        padding: 0px;
        opacity: 0;
        visibility: hidden;
        @include transition(.2s ease-in-out);
        .icon {
          width: 50px;
          height: 50px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -25px;
          margin-left: -25px;
          background: $color-secondary;
          color: $color-white;
          border-radius: 100%;
          text-align: center;
          line-height: 50px;
          vertical-align: middle;
          &:hover {
            border: 1px solid $color-white;
            line-height: 48px;
          }
        }
      }
    }
    .cart-text {
      background: none;
      font-size: 14px;
      color: $color-white;
      width: 65%;
      float: right;
      a {
        background: none;
        padding: 0px;
        display: block;
        font-size: 14px;
        font-weight: $normal;
        margin-bottom: 5px;
        color: $color-white;
        &:hover {
          background: none;
        }
      }
      .price {
        font-weight: $bold;
      }
    }
    &:hover .remove {
      visibility: visible;
      opacity: 1;
    }
    .total {
      text-transform: uppercase;
      color: $color-white;
      font-size: 13px;
      .amount {
        font-weight: $bold;
        font-size: 18px;
      }
    }
    .btn {
      padding: 10px 10px;
      text-transform: uppercase;
      font-weight: $bold;
      font-size: 14px;
    }
  }
  .cart-icon {
    position: relative;
    padding: 12px 15px;
    width: 50px;
    height: 50px;
    display: block;
    color: $color-white;
    background: $color-primary;
    .fa {
      font-size: 20px;
    }
    &:hover {
      background: $color-primary;
      color: $color-white;
      cursor: pointer;
    }
  }
}
