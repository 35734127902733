@font-face {
  font-family: 'energijabikes-icons';
  src: url('../fonts/energijabikes-icons.eot?mybvwg');
  src: url('../fonts/energijabikes-icons.eot?mybvwg#iefix') format('embedded-opentype'), url('../fonts/energijabikes-icons.ttf?mybvwg') format('truetype'), url('../fonts/energijabikes-icons.woff?mybvwg') format('woff'), url('../fonts/energijabikes-icons.svg?mybvwg#energijabikes-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="fa-"],
[class*=" fa-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  display: inline-block;
  font-family: 'energijabikes-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa {
  width: 20px;
  text-align: center;
  font-size: 16px;
  vertical-align: baseline;
}

.fa-link:before {
  content: "\f0c1";
}

.fa-search:before {
  content: "\f002";
}

.fa-envelope-o:before {
  content: "\f003";
}

.fa-user:before {
  content: "\f007";
}

.fa-check:before {
  content: "\f00c";
}

.fa-close:before {
  content: "\f00d";
}

.fa-clock-o:before {
  content: "\f017";
}

.fa-times:before {
  content: "\f00d";
}

.fa-cog:before {
  content: "\f013";
}

.fa-gear:before {
  content: "\f013";
}

.fa-trash-o:before {
  content: "\f014";
}

.fa-home:before {
  content: "\f015";
}

.fa-arrow-circle-o-down:before {
  content: "\f01a";
}

.fa-arrow-circle-o-up:before {
  content: "\f01b";
}

.fa-play-circle-o:before {
  content: "\f01d";
}

.fa-repeat:before {
  content: "\f01e";
}

.fa-rotate-right:before {
  content: "\f01e";
}

.fa-refresh:before {
  content: "\f021";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-pencil:before {
  content: "\f040";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-edit:before {
  content: "\f044";
}

.fa-pencil-square-o:before {
  content: "\f044";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-plus:before {
  content: "\f067";
}

.fa-minus:before {
  content: "\f068";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-calendar:before {
  content: "\f073";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-arrows-v:before {
  content: "\f07d";
}

.fa-arrows-h:before {
  content: "\f07e";
}

.fa-phone:before {
  content: "\f095";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-f:before {
  content: "\f09a";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-group:before {
  content: "\f0c0";
}

.fa-users:before {
  content: "\f0c0";
}

.fa-floppy-o:before {
  content: "\f0c7";
}

.fa-save:before {
  content: "\f0c7";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-rotate-left:before {
  content: "\f0e2";
}

.fa-undo:before {
  content: "\f0e2";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-mobile:before {
  content: "\f10b";
}

.fa-mobile-phone:before {
  content: "\f10b";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-calendar-o:before {
  content: "\f133";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-long-arrow-down:before {
  content: "\f175";
}

.fa-long-arrow-up:before {
  content: "\f176";
}

.fa-long-arrow-left:before {
  content: "\f177";
}

.fa-long-arrow-right:before {
  content: "\f178";
}

.fa-arrow-circle-o-right:before {
  content: "\f18e";
}

.fa-arrow-circle-o-left:before {
  content: "\f190";
}

.fa-sliders:before {
  content: "\f1de";
}

.fa-street-view:before {
  content: "\f21d";
}
