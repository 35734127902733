/*------------------------------------*\
    Table
\*------------------------------------*/

.table {
  &.main-table {
    thead tr,
    tr.tfoot {
      background: $gray-lighter;
      border-top: 1px solid #dddddd;
      text-transform: uppercase;
      border-bottom: 1px solid #dddddd;
    }
    thead th {
      border-bottom: 1px solid #dddddd;
    }
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
