/*------------------------------------*\
    My account
\*------------------------------------*/

ul.my-account-sidebar {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    border-top-width: 0;
    a {
      display: block;
      font-size: 14px;
      padding: 10px;
      font-weight: bold;
      color: #333;
      &:hover {
        text-decoration: none;
        color: $color-primary;
      }
    }
    &.active {
      a {
        color: $color-primary;
      }
    }
    &:first-of-type {
      border-top-width: 1px;
    }
  }
}

.my-account {
  .box-account {
    .sub-title {
      h3,
      h4 {
        display: inline-block;
        vertical-align: middle;
      }
      a {
        display: inline-block;
        vertical-align: middle;
        color: #333;
        text-transform: none;
        margin-left: 12px;
      }
    }
  }
}
