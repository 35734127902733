/*------------------------------------*\
    Products
\*------------------------------------*/

.sub-title {
  position: relative;
  text-transform: uppercase;
  margin-bottom: 15px;
  h3 {
    margin: 0px;
  }
  h4 {
    margin: 0px;
  }
  &.border {
    border-bottom: 1px solid $color-primary;
  }
  &.black {
    h3 {
      color: #000;
    }
    h4 {
      color: #000;
    }
  }
  &.cart {
    margin-top: 30px;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  &.my-account {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}

.single-product {
  margin-bottom: 30px;
  &:nth-of-type(4) {
    clear: left;
    /*---------  Mobile  ---------*/
    @include breakpoint(md) {
      clear: none;
    }
  }
  &.shop {
    &:nth-of-type(4) {
      clear: left;
    }
    &:nth-of-type(4) {
      clear: none;
    }
    .image-wrap {
      .buttons {
        bottom: -80px;
        .btn {
          width: 100%;
          margin: 0px;
          &:last-of-type {
            margin: 0px;
          }
        }
      }
    }
  }
}

.product-wrap {
  border: 1px solid $color-gray;
  position: relative;
  background-color: $color-white;
  .tag {
    position: absolute;
    color: $color-white;
    top: 15px;
    z-index: 1;
    span {
      position: relative;
      padding: 6px 15px;
      height: 30px;
      display: block;
      font-weight: $bold;
    }
    &.tag-new {
      background-color: $color-secondary01;
      left: -8px;
      span {
        &:after {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 8px 6px 0;
          border-color: transparent $color-secondary transparent transparent;
          z-index: 1;
          content: " ";
          position: absolute;
          left: 0;
          bottom: -6px;
        }
      }
    }
    &.tag-sale {
      background-color: $color-primary;
      right: -8px;
      span {
        &:after {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 8px 0 0;
          border-color: #8a3800 transparent transparent transparent;
          z-index: 1;
          content: " ";
          position: absolute;
          right: 0;
          bottom: -6px;
        }
      }
    }
  }
  .image-wrap {
    position: relative;
    overflow: hidden;
    .image {
      display: block;
      /*---------  Mobile  ---------*/
      @include breakpoint(sm) {
        height: 343px;
      }
      @include breakpoint(md) {
        height: 291px;
      }
      @include breakpoint(lg) {
        height: 358px;
      }
      img {
        width: 100%;
        height: auto;
        display: block;
        color: $color-black;
        text-align: center;
      }
    }
    .buttons {
      position: absolute;
      bottom: -80px;
      left: 0px;
      width: 100%;
      @include transition(.2s ease-in-out);
      .btn {
        width: 100%;
        padding: 10px 10px;
        .fa {
          margin-right: 5px;
        }
        &:last-of-type {
          margin-left: -4px;
        }
      }
    }
    &:hover {
      img {
        opacity: 0.7;
      }
      .buttons {
        bottom: 0px;
      }
    }
  }
  .text-wrap {
    border-top: 1px solid $color-gray;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 0;
    a {
      &:hover {
        text-decoration: none;
        color: $color-primary;
        h4 {
          color: $color-primary;
        }
      }
      &:focus {
        text-decoration: none;
      }
      h4 {
        margin-top: 0px;
        margin-bottom: 0px;
        color: #000;
        padding: 0 15px;
      }
    }
    .product-categorie {
      margin-top: 15px;
      padding: 15px 15px 10px 15px;
      position: relative;
      border-top: 1px solid $color-gray;
      &:before {
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -25px;
        width: 50px;
        height: 1px;
        background-color: $color-gray01;
        content: " ";
      }
    }
  }
}

.col-md-9 .product-wrap .image-wrap .image {
  /*---------  Mobile  ---------*/
  @include breakpoint(sm) {
    height: 218px;
  }
  @include breakpoint(md) {
    height: 211px;
  }
  @include breakpoint(lg) {
    height: 260px;
  }
}

.owl-carousel-products .product-wrap .image-wrap .image {
  /*---------  Mobile  ---------*/
  @include breakpoint(sm) {
    height: 343px;
  }
  @include breakpoint(md) {
    height: 291px;
  }
  @include breakpoint(lg) {
    height: 261px;
  }
}

.product-price {
  color: $color-primary;
  font-weight: $bold;
  font-size: 0;
  margin-top: 10px;
  line-height: 100%;
  .price-box>span {
    font-size: 24px;
    line-height: 1;
  }
  span.old {
    font-weight: $normal;
    font-size: 16px;
    color: $gray;
    position: relative;
    margin-right: 4px;
    &:after {
      content: " ";
      top: 50%;
      height: 1px;
      width: 100%;
      background-color: $gray;
      position: absolute;
      left: 0px;
    }
  }
  span.label {
    padding: 0;
    color: #333;
    font-size: 13px;
    border-radius: 0;
    margin-right: 4px;
    text-transform: uppercase;
  }
  span.label.no-margin {
    margin: 0;
  }
}

.product-price-vat {
  font-size: 12px;
}

.single-item {
  .product-wrap {
    .buttons {
      position: absolute;
      bottom: -80px;
      left: 0px;
      width: 100%;
      @include transition(.2s ease-in-out);
      .btn {
        width: 100%;
        padding: 10px 10px;
        .fa {
          margin-right: 5px;
        }
        &:last-of-type {
          margin-left: 0px
        }
      }
    }
  }
}


/*------------------------------------*\
    Product tabs
\*------------------------------------*/

.product-tabs {
  margin-top: 45px;
  .nav-tabs {
    text-align: center;
    border-bottom-width: 0px;
    margin-bottom: -26px;
    @include breakpoint(xs-max) {
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: -20px;
    }
    @include breakpoint(xs) {
      padding-left: 15px;
      padding-right: 15px;
    }
    li {
      float: none;
      display: inline-block;
      text-transform: uppercase;
      font-weight: $bold;
      font-size: 16px;
      position: relative;
      margin: 0 5px 0 0;
      /*---------  Mobile  ---------*/
      @include breakpoint(xs-max) {
        width: 100%;
        text-align: center;
        margin: 0 0 5px 0;
      }
      @include breakpoint(xs) {
        width: 100%;
        text-align: center;
        margin: 0 0 5px 0;
      }
      &:last-child {
        margin: 0;
      }
      a {
        padding: 15px 30px;
        border-width: 0px;
        color: $color-white;
        background-color: $color-gray02;
        border-radius: 0;
        margin: 0;
        @include breakpoint(xs-max) {
          padding: 10px 10px;
          font-size: 14px;
        }
        &:hover {
          background-color: $color-primary;
          color: $color-white;
          cursor: pointer;
        }
        &:focus {
          color: $color-white;
        }
      }
      &.active {
        a {
          background-color: $color-primary;
          border-width: 0px;
          margin-bottom: 0px;
          display: block;
        }
      }
    }
  }
  .tab-content {
    background-color: $color-gray;
    padding-top: 70px;
    &--single {
      padding-top: 45px;
    }
    .main-title {
      border-color: $color-gray01;
    }
  }
}

.owl-carousel {
  position: relative;
  .owl-nav {
    margin-top: 30px;
    margin-bottom: 45px;
    text-align: center;
    display: block !important;
    .owl-prev,
    .owl-next {
      display: inline-block;
      height: 30px;
      width: 30px;
      background-color: #000;
      color: $color-white;
      opacity: 0.6;
      font-size: 14px;
      line-height: 30px;
      vertical-align: middle;
      margin-right: 15px;
      margin-left: 15px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      @include transition(.2s ease-in-out);
      &:hover {
        cursor: pointer;
        background-color: $color-primary;
        opacity: 1;
      }
      .fa {
        font-size: 14px;
      }
    }
  }
  .owl-item {
    img {
      display: block;
      width: 100%;
    }
    .single-item {
      margin: 0 15px;
    }
  }
}

.slick-carousel {
  overflow: hidden;
  text-align: center;
  .slick-track {
    display: inline-block;
  }
  .bla-item {
    display: inline-block;
    text-align: center;
    .firm-image {
      img {
        -webkit-filter: grayscale(1);
        -webkit-filter: grayscale(100%);
        filter: gray;
        filter: grayscale(100%);
        @include transition(.2s ease-in-out);
      }
      &:hover {
        img {
          -webkit-filter: grayscale(0);
          -webkit-filter: grayscale(0%);
          filter: none;
          filter: grayscale(0%);
        }
      }
    }
  }
}

.firm-carousel {
  .firm-image {
    display: inline-block;
  }
  img {
    max-width: 100%;
  }
}
