/*------------------------------------*\
    Swipebox
\*------------------------------------*/

#swipebox-slider {
  .slide-loading {
    background: url(../images/loader.gif) no-repeat center center;
  }
}

#swipebox-prev,
#swipebox-next,
#swipebox-close {
  background-image: url(../images/icons.png);
}
