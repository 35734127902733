/*------------------------------------*\
    Newsletter
\*------------------------------------*/

.newsletter--bottom {
  background: $color-gray;
  padding: 45px 0;
  margin-bottom: -45px;
  h2 {
    color: $color-secondary;
    text-transform: uppercase;
  }
  .form-group {
    margin-right: 80px;
  }
  .form-group input {
    float: left;
    border-right: none;
  }
}

.newsletter {
  #mc_embed_signup input.mce_inline_error {
    border-color: $alert-danger-text;
  }
  #mc_embed_signup div.mce_inline_error {
    margin: 15px 0 0 0;
    padding: 0;
    background: none;
    color: $alert-danger-text;
  }
  #mce-success-response {
    color: $alert-success-text;
    margin-top: 15px;
  }
  #mce-error-response {
    color: $alert-danger-text;
    margin-top: 15px;
    a {
      color: $color-primary;
    }
  }
  .btn {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  h5 {
    color: $color-secondary;
  }
}
