/*------------------------------------*\
    Success/Alert messages
\*------------------------------------*/

ul.messages {
  margin: 0;
  padding: 0 0 30px 0;
  list-style: none;
  li {
    list-style: none;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li span {
        color: #000;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}

p.note-msg {
  padding: 30px 15px;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
}

span.please-wait {
  margin-left: 15px;
}
