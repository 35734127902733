/*------------------------------------*\
    Cookie notice
\*------------------------------------*/

.notice-cookie {
  background-color: #000;
  position: fixed;
  bottom: 0;
  width: 100%;
  color: $color-white;
  padding: 15px;
  z-index: 9999;
  text-align: center;
  .notice-text {
    p {
      display: inline-block;
      margin: 0;
    }
    a {
      color: $color-primary;
      display: inline-block;
      &:focus {
        color: $color-primary;
      }
      &.cookie-btn {
        margin-left: 10px;
      }
    }
  }
}
