/*------------------------------------*\
    Energija Bikes
\*------------------------------------*/


/*
 TABLE OF CONTENTS
    01. Variables....................Imported via gulp and can be found in _vars.scss
    02. Default Tags.................Like <body>, <h1> etc.
    03. Components...................Other SCSS components like _dividers.scss, _navigation.scss etc.
    04. Utilities......................The general styles like align-center etc.
*/


/*------------------------------------*\
    Variables
\*------------------------------------*/

@import "vars";

/*------------------------------------*\
    Bootstrap
\*------------------------------------*/

// Core variables and mixins
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/variables";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins";
// Reset and dependencies
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/normalize";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/print";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/glyphicons";
// Core CSS
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/scaffolding";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/type";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/code";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/grid";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/tables";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/forms";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/buttons";
// Components
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/component-animations";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/dropdowns";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/button-groups";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/input-groups";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/navs";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/navbar";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/breadcrumbs";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/pagination";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/pager";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/labels";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/badges";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/jumbotron";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/thumbnails";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/alerts";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/progress-bars";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/media";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/list-group";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/panels";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/responsive-embed";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/wells";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/close";
// Components w/ JavaScript
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/modals";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/tooltip";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/popovers";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/carousel";
// Utility classes
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/utilities";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/responsive-utilities";
// Owl Carousel
@import "../../bower_components/owl.carousel/dist/assets/owl.carousel";

/*------------------------------------*\
    Tags
\*------------------------------------*/

body {
  font-family: $font-primary;
  font-size: 13px;
  a {
    color: $color-primary;
    &:hover {
      text-decoration: underline;
      color: $color-primary;
    }
    &:focus {
      color: $color-primary;
    }
  }
  .wrapper {
    position: relative;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-secondary;
  font-weight: $bold;
  color: $color-primary;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 14px;
}

.main-title {
  position: relative;
  border: 3px solid $color-gray;
  margin-bottom: 30px;
  border-left: none;
  border-right: none;
  padding: 15px 0;
  h2 {
    display: inline-block;
    padding-right: 15px;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 0px;
    position: relative;
    vertical-align: middle;
  }
}

.visible-md-block {
  @include breakpoint(md) {
    display: block !important;
  }
}


/*------------------------------------*\
   03. Components
\*------------------------------------*/

@import "components/blog";
@import "components/breadcrumbs";
@import "components/buttons";
@import "components/cart";
@import "components/checkout";
@import "components/cookie";
@import "components/footer";
@import "components/form";
@import "components/header";
@import "components/my-account";
@import "components/products";
@import "components/promos";
@import "components/registration";
@import "components/shop";
@import "components/single-product";
@import "components/success-alert";
@import "components/swipebox";
@import "components/table";
@import "components/icons";
@import "components/newsletter";

/*------------------------------------*\
   04. Helpers
\*------------------------------------*/

@import "utilities";
