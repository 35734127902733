/*------------------------------------*\
    Footer
\*------------------------------------*/

.footer-wrap {
  background: #231f20;
  padding-top: 30px;
  padding-bottom: 30px;
  color: $color-white;
  .footer-col {
    /*---------  Mobile  ---------*/
    @include breakpoint(xs-max) {
      margin-bottom: 30px;
    }
    @include breakpoint(xs) {
      margin-bottom: 30px;
    }
    @include breakpoint(sm) {
      margin-bottom: 30px;
    }
  }
  a {
    color: $color-white;
    &:hover {
      color: $color-white;
    }
  }
  h3 {
    text-transform: uppercase;
    margin-top: 0px;
  }
  .footer-about {
    p {
      margin-top: 15px;
    }
    .icon {
      height: 40px;
      width: 40px;
      background-color: #383838;
      text-align: center;
      padding-top: 8px;
      border-radius: 100%;
      font-size: 16px;
      display: inline-block;
      @include transition(.2s ease-in-out);
      &:hover {
        background-color: $color-primary;
      }
      &.fb:hover {
        background-color: #3b5998;
      }
      &.yt:hover {
        background-color: #bb0000;
      }
      &:last-of-type {
        margin-left: 8px;
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  ul {
    padding-left: 0px;
    list-style-type: none;
    li {
      margin-bottom: 5px;
    }
  }
  .under-footer {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
}

.contact-line {
  margin-bottom: 5px;
}
