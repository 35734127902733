/*------------------------------------*\
    OnePage checkout
\*------------------------------------*/

.checkout-steps {
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
    li.section {
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid $color-gray;
      &:last-child {
        padding: 0;
        border: none;
      }
      .step-title {
        span,
        h2,
        a {
          display: inline-block;
          vertical-align: middle;
        }
        .number {
          border: 2px solid #333;
          border-radius: 100%;
          padding: 3px 8px;
          font-weight: bold;
          font-size: 12px;
          margin-right: 12px;
          color: #333;
        }
        h2 {
          margin: 0;
        }
        a {
          margin-left: 12px;
          color: #333;
        }
      }
      &.active {
        .number {
          background-color: #333;
          color: $color-white;
        }
      }
    }
  }
  .step {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid $color-gray;
    h3 {
      margin: 0 0 15px 0;
      color: $gray;
    }
    ul.form-list,
    .sp-methods ul {
      list-style: none;
      padding: 0;
      margin: 0;
      input,
      label {
        margin: 0;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
      }
    }
    fieldset ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
  .buttons-set {
    text-align: right;
    /*---------  Mobile  ---------*/
    @include breakpoint(xs-max) {
      text-align: center;
      &:first-of-type {
        margin-bottom: 15px;
      }
      &:last-of-type {
        .btn {
          margin-bottom: 15px;
        }
      }
    }
    @include breakpoint(xs) {
      text-align: center;
      &:first-of-type {
        margin-bottom: 15px;
      }
    }
    p.back-link {
      float: left;
      margin: 0;
      line-height: 33px;
    }
  }
}

.order-review {
  table {
    .cart-price {
      font-size: 14px;
      font-weight: 700;
      line-height: 100%;
    }
  }
}

.checkout-steps-sidebar {
  /*---------  Mobile  ---------*/
  @include breakpoint(xs-max) {
    margin-bottom: 30px;
  }
  @include breakpoint(xs) {
    margin-bottom: 30px;
  }
  dl {
    margin: 0;
    dd.complete {
      margin: 10px 0 0 10px;
      font-size: 13px;
    }
  }
  dl div {
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    border-top-width: 0;
    padding: 10px;
    font-size: 14px;
    &:first-of-type {
      border-top-width: 1px;
    }
  }
}
