/*------------------------------------*\
    Registration
\*------------------------------------*/

.gray-wrap {
  background-color: $gray-lighter;
  &.border {
    border: 1px solid #dddddd;
  }
  .wrapper {
    padding: 30px;
    padding-top: 0px;
    h3 {
      margin-top: 30px;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
    .btn {
      margin-top: 30px;
    }
    .form-group {
      &:first-of-type {
        margin-top: 30px;
      }
    }
  }
}

.table-wrap {
  display: table;
  height: 100%;
  margin-bottom: 30px;
  .cell-wrap {
    display: table-cell;
    float: none;
    height: 100%;
    /*---------  Mobile  ---------*/
    @include breakpoint(xs-max) {
      float: left;
      margin-bottom: 30px;
    }
    .inside-div {
      height: 100%;
      position: relative;
    }
  }
}

.contact-sidebar {
  margin-top: 25px;
  h3 {
    text-transform: uppercase;
  }
  .time-table {
    text-transform: uppercase;
    .week-day {
      margin-bottom: 5px;
      dt {
        display: inline-block;
      }
      dd {
        float: right;
      }
    }
  }
}
